import React, {useState, useEffect} from "react";
import {Button, Checkbox, Container, Flag, Grid, Icon, Menu, Message, Popup, Table} from "semantic-ui-react";
import ReactFileReader from "react-file-reader";
import CountryCodes from "../../../Utils/CountryCodes";

export default function TokenGeofencingImportStep(props) {
    const [selectedRule, setSelectedRule] = useState("");
    const [selectedId, setSelectedId] = useState("");

    useEffect(function onOverrideSelect() {
        if (selectedId && props.geofencesToImport.hasOwnProperty(selectedId)) {
            setSelectedRule(props.geofencesToImport[selectedId].rule);
        } else {
            setSelectedRule({});
        }
    }, [selectedId]);


    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={8} textAlign="left">
                    <ReactFileReader handleFiles={props.importRules} fileTypes={[".json"]} multipleFiles={false} base64={false}>
                        <Button secondary>Select File to Import</Button>
                    </ReactFileReader>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    {
                        Object.keys(props.geofencesToImport).length > 0 ?
                            <Button primary onClick={() => props.arrangeGeofencesClicked()}>Arrange Updated Geolocation Data</Button> : ""
                    }
                </Grid.Column>
            </Grid.Row>
            {
                Object.keys(props.geofencesToImport).length > 0 ?
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Container fluid>
                                <Menu vertical fluid secondary>
                                    {
                                        Object.keys(props.geofencesToImport).map(name => {
                                            const geofence = props.geofencesToImport[name];
                                            if (geofence) {
                                                const status = geofence.status;
                                                let icon =
                                                    status === "error" ? "x" :
                                                    status === "success"? "check" :
                                                    status === "invalid" ? "ban" :
                                                    status === "saving" ? "spinner" :
                                                    status === "warning" ? "exclamation" : "plus";
                                                let statusColor =
                                                    status === "error" || status === "invalid" ? "red" :
                                                    status === "saving" ? "blue" :
                                                    status === "warning" ? "yellow" :
                                                    status === "success" ? "green" : "black";
                                                return (
                                                    <Menu.Item
                                                        key={`geofence-import-${name}`}
                                                        active={name === selectedId}
                                                        onClick={() => setSelectedId(name)}
                                                    >
                                                        <Checkbox
                                                            checked={props.geofencesToImport[name].import}
                                                            onClick={
                                                                (event, {checked}) => {
                                                                    if (props.geofencesToImport[name].status !== "error") {
                                                                        const updatedImportedRules = Object.assign({}, props.geofencesToImport);
                                                                        updatedImportedRules[name] = Object.assign({}, props.geofencesToImport[name], {import: checked});
                                                                        props.setGeofencesToImport(updatedImportedRules);
                                                                    }
                                                                }
                                                            }
                                                            disabled={props.geofencesToImport[name].status === "error"}
                                                        />
                                                        &nbsp;{name}
                                                        <Popup
                                                            hoverable
                                                            content={props.geofencesToImport[name].details}
                                                            trigger={<Icon name={icon} size="large" color={statusColor} loading={props.saving && status === "saving"} />}
                                                        />
                                                    </Menu.Item>
                                                );
                                            } else {
                                                console.log(`geofence with id ${name} was not found or invalid`, props.geofencesToImport[name], props.geofencesToImport);
                                            }
                                        })
                                    }
                                </Menu>
                            </Container>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Container fluid>
                                {
                                    selectedId.length === 0 ?
                                        <Message icon color="yellow">
                                            <Icon name="info" />
                                            <Message.Content>
                                                Select a rule to see its details and import status.
                                            </Message.Content>
                                        </Message> :
                                        <Container>
                                            <Table fixed celled striped>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Geofence Name</Table.HeaderCell>
                                                        <Table.Cell>{selectedId}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Description</Table.HeaderCell>
                                                        <Table.Cell>{selectedRule.description}</Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        selectedRule.hasOwnProperty("country") ?
                                                            selectedRule.country.map((country, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Countries" : ""}</Table.HeaderCell>
                                                                        <Table.Cell><pre>[{country}]</pre> <Flag name={country} /> {CountryCodes[country]}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Countries</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no countries.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("cidr") ?
                                                            selectedRule.cidr.map((cidr, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "CIDRs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{cidr}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;CIDRs</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no CIDRs.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("media") ?
                                                            selectedRule.media.map((media, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Media" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{media}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Media</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no media.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("mvpd") ?
                                                            selectedRule.mvpd.map((mvpd, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "MVPDs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{mvpd}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;MVPDs</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no MVPDs.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("requestor") ?
                                                            selectedRule.requestor.map((requestor, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Requestors" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{requestor}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Requestors</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no requestors.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("appidnetwork") ?
                                                            selectedRule.appidnetwork.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "App ID Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;App ID Networks</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no App ID networks.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("assetnetwork") ?
                                                            selectedRule.assetnetwork.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Asset Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Asset Networks</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no asset networks.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("tokennetwork") ?
                                                            selectedRule.tokennetwork.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Token Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Token Networks</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no token networks.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("assetentitlement") ?
                                                            selectedRule.assetentitlement.map((entitlement, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Asset Entitlements" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{entitlement}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Asset Entitlements</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no asset entitlements.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("tokenentitlement") ?
                                                            selectedRule.tokenentitlement.map((entitlement, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Token Entitlements" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{entitlement}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Token Entitlements</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no token entitlements.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("assetproduct") ?
                                                            selectedRule.assetproduct.map((product, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Asset Products" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{product}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Asset Products</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no asset products.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("tokenproduct") ?
                                                            selectedRule.tokenproduct.map((product, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Token Products" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{product}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Token Products</Table.HeaderCell>
                                                                <Table.Cell>This geofence specifies no token products.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        <Table.Row>
                                                            <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Start</Table.HeaderCell>
                                                            <Table.Cell>
                                                                {selectedRule.hasOwnProperty("start") ? new Date(selectedRule.start).toLocaleString() : "This geofence specifies no start time."}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    }
                                                    {
                                                        <Table.Row>
                                                            <Table.HeaderCell>&nbsp;&nbsp;&nbsp;End</Table.HeaderCell>
                                                            <Table.Cell>
                                                                {selectedRule.hasOwnProperty("end") ? new Date(selectedRule.end).toLocaleString() : "This geofence specifies no end time."}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    }
                                                </Table.Body>
                                            </Table>
                                        </Container>
                                }
                            </Container>
                        </Grid.Column>
                    </Grid.Row> : ""
            }
        </Grid>
    );
};

import React from 'react'
import TokenMdProvider from "../../../Services/TokenMdDataProvider"
import Select from "react-select"

import {Button, Container, Grid, Header} from "semantic-ui-react"

import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

class TokenMdMasterDetailView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      metadata: {},
      selectedAttr: ""
    }

    TokenMdProvider.init({baseURL: process.env.TOKEN_MD_URL});
  }

  componentWillUnmount() {
    console.log(`(componentWillUnmount) Started.`)
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  }

  componentDidMount() {
    console.log(`(componentDidMount) Started.`)
    this.getMetadata()
    const options = {
      mode: 'text'
    }
    this.jsoneditor = new JSONEditor(this.container, options)
  }

  getMetadata = () => {
    console.log(`(getMetadata) Started.`)
    TokenMdProvider.getMetadata()
      .then(payload => {
        payload.json()
          .then(metadataPayload => {
            console.log(`(getMetadata) Metadata: ${metadataPayload}`)
            if (metadataPayload["auth"]) {
              if (metadataPayload.auth.error) {
                this.props.toast("Error", metadataPayload.auth.error.message, "error")
              }
            } else {
              this.setState({
                metadata: metadataPayload
              })
            }
          })
      })
  }

  filterOnPermissions = () => {
    console.log(`(filterOnPermissions) Started`)
    let permissions = this.props.user.authz || []
    let filteredList = []

    try {
      if (permissions.indexOf('urn:all:aspen-power') !== -1) {
        console.log(`(filterOnPermissions) Master permission detected`)

        Object.keys(this.state.metadata).sort().forEach(data => {
          filteredList.push({
            value: data,
            label: data
          })
        })

      } else {
        Object.keys(this.state.metadata).sort().forEach(option => {
          let permission = `urn:tokenmd:${option.toLowerCase()}`

          if (permissions.indexOf(permission) !== -1) {
            filteredList.push({
              value: option,
              label: option
            })
          }
        })
      }
    } catch (error) {
      this.props.toast('Message', error.toString(), "error")
    }

    return filteredList
  }

  handleReactSelect = selectData => {
    console.log(`(handleReactSelect) Started, selectData: ${JSON.stringify(selectData)}`)
    let metadataAttr = selectData.value
    let attrData = ""

    try {
      attrData = this.state.metadata[metadataAttr]
    } catch (error) {
      attrData = ""
    }

    this.setState({
      selectedAttr: selectData
    })

    this.jsoneditor.set(attrData)
  }

  saveMetadata = () => {
    console.log(`(saveMetadata) Started.`)
    try {
      let attrData = this.jsoneditor.get()
      let selectedAttr = this.state.selectedAttr.value
      let metadata = {}
      metadata[selectedAttr] = attrData
      console.log(`(saveMetadata) metadata: ${JSON.stringify(metadata)}`)
      TokenMdProvider.setMetadata(metadata)
        .then(payload => {
          payload.json()
            .then(json => {
              this.props.toast("Message", "Token MD updated: success", "success")
            })
        })
    } catch (error) {
      console.log(`(saveMetadata) Error saving metadata: ${error}`)
      this.props.toast("Error", error.toString(), "error")
    }
  }

  render() {
    return (<Grid>
      <Grid.Column className="masterContainer">
        {
          this.state.metadata &&
          <Grid.Row style={{paddingBottom: "3ch"}}>
            <h4>Select Attribute:</h4>
            <Select
              value={this.state.selectedAttr}
              onChange={this.handleReactSelect}
              options={this.filterOnPermissions()}/>
          </Grid.Row>
        }

          <Container style={{minHeight: "calc(100vh-40px)"}} fluid>
            <Grid.Row>
              <h4>Attribute Data:</h4>
              <div style={{height: "62vh"}} ref={elem => this.container = elem}/>
            </Grid.Row>
            {
              this.state.selectedAttr &&
              <Grid.Row style={{paddingTop: "3ch"}}>
                <Button onClick={this.saveMetadata}> Save Metadata </Button>
              </Grid.Row>
            }
          </Container>
      </Grid.Column>
    </Grid>)
  }
}

export default TokenMdMasterDetailView

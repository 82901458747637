import React, {useEffect, useState} from "react";
import TokenMetadataDataProvider from "../../../Services/TokenMdDataProvider";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ARADataProvider from "../../../Services/ARADataProvider";
import OriginCDNMapperDataProvider from "../../../Services/OriginCDNMapperDataProvider";
import {Grid, Tab} from "semantic-ui-react";
import TokenGeofencingArrangementTab from "./TokenGeofencingArrangementTab";
import TokenGeofencingImportTab from "./TokenGeofencingImportTab";

TokenMetadataDataProvider.init({baseURL: process.env.TOKEN_MD_URL});
TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);
ARADataProvider.init({baseURL: process.env.PROD_ARA});
OriginCDNMapperDataProvider.init({baseURL: process.env.ORIGIN_CDN_MAPPER_URL});


export default function TokenGeofencingMasterDetailView(props) {
    const [geolocationData, setGeolocationData] = useState([]);
    const [loadingGeolocationData, setLoadingGeolocationData] = useState(false);
    const [loadingGeolocationDataErrorMessage, setLoadingGeolocationDataErrorMessage] = useState("");
    const [brandMapping, setBrandMapping] = useState({});
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = false;
        for (const permission of props.userPermissions) {
            const userHasAspenPower = permission.service === "all" && permission.module === "all" && permission.scope === "all" && permission.role === "aspen-power";
            const userHasRelevantPermission = permission.service === props.service && permission.module === props.module && permission.role === "editor";
            updatedUserCanEdit ||= userHasAspenPower || userHasRelevantPermission;
            if (updatedUserCanEdit) {
                break;
            }
        }
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions)]);

    const refreshGeolocationData = () => {
        setLoadingGeolocationData(true);
        setLoadingGeolocationDataErrorMessage("");
        TokenMetadataDataProvider.getMetadata().then(response => {
            return response.json().then(json => {
                if (json.hasOwnProperty("brand_mapping")) {
                    const updatedBrandMapping = {};
                    for (const brand of Object.keys(json.brand_mapping)) {
                        for (const key of json.brand_mapping[brand]) {
                            updatedBrandMapping[key] = brand;
                        }
                    }
                    setBrandMapping(updatedBrandMapping);
                } else {
                    setBrandMapping({});
                }
                if (json.hasOwnProperty("geolocation_settings_v2")) {
                    return json.geolocation_settings_v2;
                } else {
                    return [];
                }
            });
        }).then(geolocationData => {
            const enrichedGeolocationData = geolocationData.slice();
            for (let i = 0; i < enrichedGeolocationData.length; i++) {
                enrichedGeolocationData[i].index = i;
            }
            setGeolocationData(enrichedGeolocationData);
        }).catch(error => {
            console.error(error);
            setLoadingGeolocationDataErrorMessage("There was an error retrieving geolocation settings from Token.");
        }).finally(() => {
            setLoadingGeolocationData(false);
        });
    };

    return (
       <Grid>
           <Grid.Row />
           <Grid.Row>
               <Grid.Column width={16}>
                   <Tab
                       style={{maxHeight: "85vh", overflowY: "auto", overflowX: "hidden"}}
                       panes={[
                           {
                               menuItem: "Arrange Geolocation Settings",
                               render: () =>
                                   <TokenGeofencingArrangementTab
                                       TokenMetadataDataProvider={TokenMetadataDataProvider}
                                       TVEManagerDataProvider={TVEManagerDataProvider}
                                       ARADataProvider={ARADataProvider}
                                       OriginCDNMapperDataProvider={OriginCDNMapperDataProvider}
                                       geolocationData={geolocationData}
                                       loadingGeolocationData={loadingGeolocationData}
                                       loadingGeolocationDataErrorMessage={loadingGeolocationDataErrorMessage}
                                       refreshGeolocationData={refreshGeolocationData}
                                       brandMapping={brandMapping}
                                       service={props.service}
                                       module={props.module}
                                       user={props.user}
                                       permissions={props.permissions}
                                       userPermissions={props.userPermissions}
                                       userCanEdit={userCanEdit}
                                   />
                           },
                           userCanEdit && {
                               menuItem: "Import Geolocation Settings",
                               render: () =>
                                   <TokenGeofencingImportTab
                                       TokenMetadataDataProvider={TokenMetadataDataProvider}
                                       geolocationData={geolocationData}
                                       loadingGeolocationData={loadingGeolocationData}
                                       loadingGeolocationDataErrorMessage={loadingGeolocationDataErrorMessage}
                                   />
                           }
                       ]}
                   />
               </Grid.Column>
           </Grid.Row>
       </Grid>
   )
};

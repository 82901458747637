import React, {useState, useEffect} from "react";
import {Button, Checkbox, Container, Form, Grid, Icon, Input, Menu, Message} from "semantic-ui-react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import TokenGeofencingExportModal from "./TokenGeofencingExportModal";
import ContingentButton from "../../ContingentButton";

export default function TokenGeofencingSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredGeofences, setFilteredGeofences] = useState([]);

    useEffect(function updateFilteredGeofences() {
        if (filter.length > 0) {
            const updatedFilteredGeofences = [];
            for (const geofence of props.geolocationData) {
                if (geofence.hasOwnProperty("country") && geofence.country.toString().toLowerCase().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                } else if (geofence.hasOwnProperty("mvpd") && geofence.mvpd.toString().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                } else if (geofence.hasOwnProperty("product") && geofence.product.toString().toLowerCase().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                } else if (geofence.hasOwnProperty("network") && geofence.network.toString().toLowerCase().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                } else if (geofence.hasOwnProperty("name") && geofence.name.toLowerCase().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                } else if (geofence.hasOwnProperty("description") && geofence.description.toLowerCase().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                } else if (geofence.hasOwnProperty("NOTE") && geofence.NOTE.toLowerCase().includes(filter)) {
                    updatedFilteredGeofences.push(geofence);
                }
            }
            setFilteredGeofences(updatedFilteredGeofences);
        } else {
            setFilteredGeofences(props.geolocationData);
        }

    }, [filter, JSON.stringify(props.geolocationData)]);

    const onDragEnd = result => {
        if (filter) {
            props.toast("Error", "You must clear the filter before you can modify the order of geolocation settings.");
            return;
        }

        const {source, destination} = result;

        if (!destination || (source.index === destination.index)) {
            return;
        }

        const updatedGeofences = props.geolocationData.slice();

        const [removed] = updatedGeofences.splice(source.index, 1);
        updatedGeofences.splice(destination.index, 0, removed);
        props.setGeolocationData(updatedGeofences);
    };

    return (
        <Grid>
            <Grid.Column>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <ContingentButton
                                icon
                                secondary
                                onClick={() => props.setNewClicked(true)}
                                fluid
                                service={props.service}
                                module={props.module}
                                scope="all"
                                allPermissions={props.permissions}
                                user={props.user}
                            ><Icon name="plus"/> Create New</ContingentButton>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <TokenGeofencingExportModal
                                open={props.openExportModal}
                                setOpen={props.setOpenExportModal}
                                rulesSelected={props.rulesSelected}
                                trigger={
                                    <Button
                                        icon
                                        fluid
                                        onClick={() => props.setOpenExportModal(true)}
                                        disabled={Object.keys(props.rulesSelected).length < 1}
                                    >
                                        <Icon name="download"/> Export
                                    </Button>
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.refreshGeolocationData()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="search"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "62vh", overflowY: "auto"}}>
                    {
                        props.loadingGeolocationData ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Loading Geolocation Data...
                                </Message.Content>
                            </Message> :
                            <Menu vertical fluid>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {
                                        filteredGeofences.length > 0 ?
                                            <Droppable droppableId={"droppable"}>
                                                {
                                                    provided =>
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {
                                                                filteredGeofences.map((geofence, index) =>
                                                                    <Draggable key={`geofence-${index}`} draggableId={`geofence-${index}`} index={index}>
                                                                        {
                                                                            provided =>
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <Menu.Item
                                                                                        innerRef={provided.innerRef}
                                                                                        {...provided.droppableProps}
                                                                                        active={geofence.name && props.selectedGeofence.name ? props.selectedGeofence.name === geofence.name : JSON.stringify(props.selectedGeofence) === JSON.stringify(geofence)}
                                                                                        onClick={
                                                                                            () => {
                                                                                                props.setSelectedGeofence(geofence);
                                                                                            }
                                                                                        }
                                                                                        fluid
                                                                                    >
                                                                                        <Grid>
                                                                                            <Grid.Row verticalAlign="middle">
                                                                                                <Grid.Column width={2}>
                                                                                                    <Checkbox
                                                                                                        checked={Object.keys(props.rulesSelected).includes(geofence.name)}
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                let updatedRulesSelected = {};
                                                                                                                const isRuleCurrentlySelected = Object.keys(props.rulesSelected).includes(geofence.name);
                                                                                                                for (const selectedRule of Object.keys(props.rulesSelected)) {
                                                                                                                    if (selectedRule !== geofence.name || !isRuleCurrentlySelected) {
                                                                                                                        updatedRulesSelected[selectedRule] = Object.assign({}, props.rulesSelected[selectedRule]);
                                                                                                                    }
                                                                                                                }

                                                                                                                if (!isRuleCurrentlySelected) {
                                                                                                                    updatedRulesSelected[geofence.name] = Object.assign({}, geofence);
                                                                                                                }
                                                                                                                props.setRulesSelected(updatedRulesSelected);
                                                                                                            }
                                                                                                        }
                                                                                                    />
                                                                                                </Grid.Column>
                                                                                                <Grid.Column width={14}>
                                                                                                    <h5>{geofence.name || (geofence.country ? geofence.country.join(", ") : geofence.mvpd ? geofence.mvpd.join(", ") : geofence.product)}</h5>
                                                                                                    <>{geofence.description}</>
                                                                                                    {provided.placeholder}
                                                                                                </Grid.Column>
                                                                                            </Grid.Row>
                                                                                        </Grid>
                                                                                    </Menu.Item>
                                                                                </div>

                                                                        }
                                                                    </Draggable>
                                                                )
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                }
                                            </Droppable> :
                                            <Message icon color="yellow">
                                                <Icon name="warning" />
                                                <Message.Content>No geolocation settings match your current search filter.</Message.Content>
                                            </Message>
                                    }
                                </DragDropContext>
                            </Menu>
                    }
                </Container>
            </Grid.Column>
        </Grid>
    );
};

import React, {useState, useEffect} from "react";
import {Button, Container, Flag, Grid, Icon, Input, Menu, Message, Modal, Table} from "semantic-ui-react";
import FileSaver from "file-saver";
import CountryCodes from "../../../Utils/CountryCodes";

export default function TokenGeofencingExportModal(props) {
    const [ruleIds, setRuleIds] = useState([]);
    const [ruleValues, setRuleValues] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [selectedRule, setSelectedRule] = useState({});
    const [exportFilename, setExportFilename] = useState("export.json");

    useEffect(function updateProfileIds() {
        setRuleIds(Object.keys(props.rulesSelected));
        setRuleValues(props.rulesSelected);
    }, [props.rulesSelected]);

    useEffect(function updateSelectedRule() {
        if (ruleValues.hasOwnProperty(selectedId)) {
            setSelectedRule(ruleValues[selectedId]);
        } else {
            setSelectedRule({});
        }
    }, [selectedId]);

    useEffect(function clearOnClose() {
        if (!props.open) {
            setSelectedRule({});
            setSelectedId("");
            setExportFilename("export.json");
        }
    }, [props.open]);

    const exportJSON = filename => {
        if (!filename) {
            filename = "export.json";
        }
        const records = [];
        for (const record of Object.values(props.rulesSelected)) {
            delete record.index;
            records.push(record);
        }

        let file = new File([JSON.stringify(records)], filename, {type: "application/json;charset=utf-8"});
        FileSaver.saveAs(file);
        props.setOpen(false);
    };

    return (
        <Modal
            size="large"
            open={props.open}
            onClose={() => props.setOpen(false)}
            trigger={props.trigger}
            style={{position: "relative"}}
        >
            <Modal.Header>
                Token Geofencing Export
            </Modal.Header>
            <Modal.Content scrolling style={{height: "50vh"}}>
                <Modal.Description>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Input
                                    label="Export Filename"
                                    value={exportFilename}
                                    onChange={(event, {value}) => setExportFilename(value)}
                                    fluid
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={3}>
                            <Menu vertical fluid secondary>
                                {
                                    ruleIds.map(id =>
                                        <Menu.Item
                                            key={`rule-export-${id}`}
                                            active={id === selectedId}
                                            onClick={() => setSelectedId(id)}
                                        >{id}</Menu.Item>
                                    )
                                }
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {
                                !selectedId ?
                                    <Message icon color="yellow">
                                        <Icon name="info"/>
                                        If you wish to inspect the rules to export, you can click on the IDs on the left. You can also just click the export button.
                                    </Message> :
                                    Object.keys(selectedRule).length > 0 ?
                                        <Container>
                                            <Table fixed celled striped>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Rule ID</Table.HeaderCell>
                                                        <Table.Cell>{selectedRule.name}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Description</Table.HeaderCell>
                                                        <Table.Cell>{selectedRule.description}</Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        selectedRule.hasOwnProperty("country") ?
                                                            selectedRule.country.map((country, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Countries" : ""}</Table.HeaderCell>
                                                                        <Table.Cell><Flag name={country} /> <pre>[{country}]</pre> {CountryCodes[country]}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("cidr") ?
                                                            selectedRule.cidr.map((cidr, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "CIDRs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{cidr}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("media") ?
                                                            selectedRule.media.map((media, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Media" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{media}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("mvpd") ?
                                                            selectedRule.mvpd.map((mvpd, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "MVPDs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{mvpd}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("requestor") ?
                                                            selectedRule.requestor.map((requestor, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Requestors" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{requestor}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("appidnetwork") ?
                                                            selectedRule.appidnetwork.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "App ID Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("assetnetwork") ?
                                                            selectedRule.assetnetwork.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Asset Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("tokennetwork") ?
                                                            selectedRule.tokennetwork.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Token Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("assetentitlement") ?
                                                            selectedRule.assetentitlement.map((entitlement, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Asset Entitlements" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{entitlement}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("tokenentitlement") ?
                                                            selectedRule.tokenentitlement.map((entitlement, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Token Entitlements" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{entitlement}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("assetproduct") ?
                                                            selectedRule.assetproduct.map((product, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Asset Products" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{product}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("tokenproduct") ?
                                                            selectedRule.tokenproduct.map((product, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Token Products" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{product}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("start") ?
                                                            <Table.Row>
                                                                <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Start</Table.HeaderCell>
                                                                <Table.Cell>{new Date(selectedRule.start).toLocaleString()}</Table.Cell>
                                                            </Table.Row> : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("end") ?
                                                            <Table.Row>
                                                                <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;End</Table.HeaderCell>
                                                                <Table.Cell>{new Date(selectedRule.end).toLocaleString()}</Table.Cell>
                                                            </Table.Row> : ""
                                                    }
                                                </Table.Body>
                                            </Table>
                                        </Container> : ""
                            }
                        </Grid.Column>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)} secondary>Cancel</Button>
                <Button onClick={() => exportJSON(exportFilename)} primary>Export</Button>
            </Modal.Actions>
        </Modal>
    );
};

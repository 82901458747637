import React, {useState, useEffect} from "react";
import {Container, Grid, Icon, Step} from "semantic-ui-react";
import TokenGeofencingImportStep from "./TokenGeofencingImportStep";
import TokenGeofencingArrangementStep from "./TokenGeofencingArrangementStep";

export default function TokenGeofencingImportTab(props) {
    const [geofencesToImport, setGeofencesToImport] = useState([]);
    const [allGeofences, setAllGeofences] = useState({});
    const [importError, setImportError] = useState("");
    const [saving, setSaving] = useState(false);
    const [isImportStep, setIsImportStep] = useState(true);
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");

    useEffect(function updateAllRuleIds() {
        if (Array.isArray(props.geolocationData) && props.geolocationData.length > 0) {
            const updatedAllGeofences = {};
            for (const geofence of props.geolocationData) {
                updatedAllGeofences[geofence.name] = geofence;
            }
            console.log("TokenGeofencingImportTab.updateAllRuleIds: updated all geofences: ", updatedAllGeofences);
            setAllGeofences(updatedAllGeofences);
        } else {
            setAllGeofences({});
        }
        console.log("TokenGeofencingImportTab.updateAllRuleIds: ", props.geolocationData);
    }, [JSON.stringify(props.geolocationData)]);

    const importRules = files => {
        setImportError("");
        const file = files.item(0);
        file.text().then(text => {
            const json = JSON.parse(text);
            if (!Array.isArray(json)) {
                setImportError("The file imported is not properly formatted, or was modified after export.");
                return;
            }

            const updatedGeofences = {};

            for (const rule of json) {
                const name = rule.name;
                if (name) {
                    const geofenceExists = allGeofences.hasOwnProperty(name);
                    updatedGeofences[name] = {
                        import: !geofenceExists,
                        status: geofenceExists ? "error" : "ready",
                        details: geofenceExists ? "Rule Already Exists" : "Ready to Import",
                        rule
                    };
                }
            }
            setGeofencesToImport(updatedGeofences);
        }).catch(error => {
            console.error(error);
            setImportError("There was an error importing your file.");
        });
    };

    const saveGeofences = updatedGeofences => {
        for (const geofence of updatedGeofences) {
            delete geofence.index;
        }

        const metadata = {"geolocation_settings_v2": updatedGeofences};
        setSaving(true);
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
        console.log(updatedGeofences);
        props.TokenMetadataDataProvider.setMetadata(metadata).then(response => {
            response.json().then(json => {
                setSaveSuccessMessage("Geolocation settings were successfully saved.");
            });
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving geolocation settings.");
        }).finally(() => {
            setSaving(false);
            props.refreshGeolocationData();
        });
    };

    const arrangeGeofencesClicked = () => {
        setIsImportStep(false);
    };

    const goBackClicked = () => {
        setIsImportStep(true);
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Step.Group attached="top">
                    <Step active={isImportStep} completed={!isImportStep}>
                        <Icon name="upload" />
                        <Step.Content>
                            <Step.Title>Import Geolocation Settings</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step active={!isImportStep}>
                        <Icon name="random" />
                        <Step.Content>
                            <Step.Title>Arrange Geolocation Settings</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Container fluid>
                        {
                            isImportStep ?
                                <TokenGeofencingImportStep
                                    geofencesToImport={geofencesToImport}
                                    setGeofencesToImport={setGeofencesToImport}
                                    importRules={importRules}
                                    importError={importError}
                                    setImportError={setImportError}
                                    arrangeGeofencesClicked={arrangeGeofencesClicked}
                                /> :
                                <TokenGeofencingArrangementStep
                                    geofencesToImport={geofencesToImport}
                                    allGeofences={allGeofences}
                                    saveGeofences={saveGeofences}
                                    saving={saving}
                                    saveErrorMessage={saveErrorMessage}
                                    saveSuccessMessage={saveSuccessMessage}
                                    goBackClicked={goBackClicked}
                                />
                        }
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
